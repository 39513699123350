import React, { useEffect, useState } from "react";
import styles from "./CheckoutModal.module.css";
import { IoMdClose } from "react-icons/io";
import { httpsCallable } from "firebase/functions";
import {
  functions,
  auth,
  getSavedWallet,
  getPayableAmount,
} from "../../utils/firebase";

import {
  TREASURY_ADDRESS,
  XVA_LOT_PRICE_USD,
  XVA_LOT_SIZE,
} from "../../utils/web3config";

import { toast } from "react-toastify";
import Spinner from "../spinner/spinner";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import {
  TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createTransferInstruction,
} from "@solana/spl-token";

import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

interface PopupModalProps {
  isOpen: boolean;
  onClose: () => void;
  setQuantity: (num: string) => void;
  email: string | null;
  walletAddress: string | undefined;
  tokenPrice: number;
  quantity: string;
  total: number;
  setTotal: (num: number) => void;
}

const CheckoutModal = ({
  isOpen,
  onClose,
  email,
  total,
  walletAddress,
  tokenPrice,
  quantity,
  setTotal,
  setQuantity,
}: PopupModalProps) => {
  const signer = useWallet();
  const address = signer.publicKey;
  const { connection } = useConnection();

  const [currency, setCurrency] = useState("SOL");
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [sol, setSol] = useState(0.5);

  if (!isOpen) return null;

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    console.log(value);
    const numericInput = value.replace(/[^0-9.]/g, ""); // Allow digits and decimal point
    let floatValue = parseFloat(numericInput);

    // Do not immediately apply minimum limit if the user is typing a valid decimal value

    console.log(floatValue);

    if (floatValue > 20) {
      value = "20"; // Apply maximum limit
    }

    setQuantity(value);
    const totalCost = XVA_LOT_PRICE_USD * floatValue;
    setTotal(parseFloat(totalCost.toFixed(2)));
  };

  const handleClose = () => {
    setIsLoading1(false);
    setIsLoading2(false);
    onClose();
  };

  const buyTokenV3 = async () => {
    setIsLoading2(true);
    let transactionId;
    let quantity_value = parseFloat(quantity);

    if (quantity_value < 0.5) {
      toast.error(
        "Minimum Quantity Required: You must purchase at least 0.5 units."
      );
      return;
    } else if (quantity_value > 20) {
      toast.error(
        "Maximum Quantity Exceeded: You can purchase up to 20 units at a time."
      );
      return;
    }

    if (!signer) {
      toast.error("No crypto wallet detected");
      setIsLoading2(false);
      return;
    }

    if (!address || !signer.signTransaction) {
      toast.error("No wallet address detected");
      setIsLoading2(false);
      return;
    }

    const balance: any = await connection.getBalance(address);
    const balanceCount = balance / 10 ** 18;
    const lamportsToSend = Math.floor(sol * web3.LAMPORTS_PER_SOL);

    let signature: string | undefined = undefined;
    for (let i = 0; i < 3; i++)
      try {
        let transaction = new web3.Transaction().add(
          web3.SystemProgram.transfer({
            fromPubkey: address,
            toPubkey: new web3.PublicKey(TREASURY_ADDRESS),
            lamports: lamportsToSend,
          })
        );

        let blockhashInfo = await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhashInfo.blockhash;
        transaction.feePayer = address;
        let signedTransaction = await signer.signTransaction(transaction);

        signature = await connection.sendRawTransaction(
          signedTransaction.serialize(),
          {
            maxRetries: 5,
            preflightCommitment: "processed",
          }
        );
        console.log("sent transaction", signature);

        await connection.confirmTransaction({
          signature,
          ...blockhashInfo,
        });

        toast.success("Transaction sent successfully");

        break;
      } catch (e: any) {
        console.error(`attempt ${i + 1} failed: ${e?.message ?? e}`);
        console.error(e);
        toast.error(e?.message || String(e));
        setIsLoading2(false);
        if (e?.message == "User rejected the request.") break;
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

    //https://registerbsctransaction-rp5h7zeemq-uc.a.run.app

    // let requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ transactionId: transactionId }),
    // };

    // try {
    //   const response = await fetch(
    //     process.env.REACT_APP_PRODUCTION_FIREBASE_FUNCTIONS_URL_REGISTER_BSC ||
    //       "http://127.0.0.1:5001/xva-collective/us-central1/registerBscTransaction",
    //     requestOptions
    //   );
    //   let promise = response.json();
    //   toast.promise(promise, {
    //     pending: "Recording transaction on database, please wait...",
    //     success: "Transaction recorded successfully",
    //     error: "Transaction recording failed, please contact us for support",
    //   });
    //   const data = await promise;
    //   if (data.success) {
    //     console.log("success verification on firebase");
    //     toast.success("Transaction Successful");
    //   } else {
    //     console.error("Unsuccessful verification of tx on firebase");
    //     toast.error(
    //       "Transaction unsuccessful, please contact support before making another payment"
    //     );
    //   }
    // } catch (error: any) {
    //   // Getting the Error details.
    //   const message = error.message;
    //   console.error(error);
    //   toast.error(message);
    // }
    setIsLoading2(false);
    onClose();
  };

  return (
    <div className={styles.checkoutModal}>
      <div className={styles.checkoutModalContent}>
        <IoMdClose className={styles.closeIcon} onClick={handleClose} />
        <h2>Confirm Your Details</h2>
        <p>Please double-check your details before proceeding:</p>
        <ul>
          <li>
            <strong>Crypto Wallet Address:</strong>{" "}
            {address?.toString() || "Not connected"}{" "}
            <span>(Tokens will be sent here, so triple-check this!)</span>
          </li>
          <li>
            <strong>SOL:</strong>{" "}
            <input
              type="text"
              value={quantity}
              onChange={handleQuantityChange}
              className="lotInput"
            />
          </li>
          <li>
            <strong>XVA Tokens:</strong> {parseFloat(quantity) * 1600 || "0"} (
            {1600} XVA Tokens per SOL)
          </li>
          {/* <li>
            <strong>Total Price:</strong> USD ${total.toFixed(2)} (USD $
            {XVA_LOT_PRICE_USD} per lot)
          </li> */}
          {/* <li>
            <strong>Total Price:</strong> {total.toFixed(2)} ($ {tokenPrice} per
            lot)
          </li> */}
        </ul>

        <div className={styles.walletbutton}>
          <WalletMultiButton />
        </div>
        {address && (
          <button
            className={styles.primaryButton}
            onClick={buyTokenV3}
            disabled={isLoading1 || isLoading2}>
            Buy
          </button>
        )}
      </div>
    </div>
  );
};

export default CheckoutModal;
