import React from "react";
import "./hero.css";

interface Props {
  refProp: React.RefObject<HTMLDivElement>;
  setIsCheckoutPopupOpen: (b: boolean) => void;
}

export default function Hero({ refProp, setIsCheckoutPopupOpen }: Props) {
  return (
    <div className="hero" id="about" ref={refProp}>
      <div className="hero1">
        <img
          className="fox"
          src={require("../../assets/XVA Fox 1.png")}
          alt=""
        />
        <div className="text">
          <div className="heading">
            <div className="heading2">
              What’s $SPLRG, you ask?
              <br />
              we’re the coin
              <br />
              with a meme twist.
              <br />
            </div>
            <img
              className="diamond-line"
              src={require("../../assets/Diamond Arrow Down.png")}
              alt=""
            />
          </div>
          <div className="section">
            We're droppin' something big: the first-ever luxury-based Web3
            currency. Yeah, you heard that right. We're talkin' exclusive 1 of 1
            tradeable luxury asset backed NFTs and a whole marketplace on SOL
            dedicated to that. <strong> Real World Assets, baby!</strong> <br />{" "}
            <br />
            Need more? How 'bout a sweet Mastercard deal that'll score you
            access to the hottest gigs and tokenizin' a mil' worth of top-shelf
            wines? And guess what? We're just gettin' warmed up. <br /> <br />
            Now, you might think this sounds like bullcrap, and yeah, maybe it
            does. But who knows? We might just surprise ya. 'Cause remember,
            we're just a meme coin... until we're not.
          </div>
        </div>
      </div>
      <div className="hero2">
        <div className="section">
          So, I would like to make a humble request. Splurge a little and join
          my mates in XVA Collective. Remember, Luxury is the new Currency in
          this Web3 neighbourhood we call home innit? <br /> <br />
          Hit the button below and get yourself a minimum of 0.05SOL to max of
          20 SOL. Each token is worth USD0.08. Pass us your wallet address and
          $SPLRG will come knocking on your wallet door within a week. 
        </div>
        <button onClick={() => setIsCheckoutPopupOpen(true)}>
          Buy $SPLRG NOW
        </button>

        <img
          className="fox-meme"
          src={require("../../assets/XVA Fox Meme 1.png")}
          alt=""
        />
      </div>
    </div>
  );
}
